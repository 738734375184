// Load Styles
import '../scss/main.scss';

// Load Bootstrap init
import * as bootstrap from "bootstrap";
import $ from 'jquery'
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
Fancybox.bind();
// Your app code
console.log(`Hello ${process.env.HELLO}`);

$(function () {
  var $videoContainer = $('.video-container');
  $videoContainer.each(function (){
    var $self = $(this),
      $videoControls = $('.video-control', $self),
      $video = $('.video', $self)[0];

    $self.on('click', $videoControls, function () {
      if ($video.paused) {
        $video.play();
        $video.controls=true
        return true;
      } else {
        $video.pause();
        $video.controls=""
        return true;
      }
    });
    $video.onpause = function () {
      $self.removeClass('video-is-playing');
    }
    $video.onplay = function () {
      $self.addClass('video-is-playing');
    }
  })

});
